
import $vuetify from '@/plugins/vuetify'
import { Vue, Component } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { System } from '@/modules/system'

@Component
export default class About extends Vue {
  common = $app.module(Common);
  system = $app.module(System);

  get appName() {
    return this.common.$store.appName
  }

  get appVersion() {
    return this.common.$store.appVersion
  }

  get appLanguage() {
    return this.common.$store.appLanguage.name
  }

  get getServerInstance() {
    return this.system.$store.getServerInstance
  }

  get getServerVersion() {
    return this.system.$store.getServerVersion
  }

  get getUserName() {
    return this.system.$store.getCurrentUser.name
  }

  get getUserCompany() {
    return this.system.$store.getCurrentUser.company
  }

  get boxStyle(): string {
    return (
      'app-about-box ' +
      ($vuetify.framework.breakpoint.mdAndUp
        ? 'app-about-margin-lg'
        : 'app-about-margin-sm')
    )
  }

  updateEnv() {
    this.common.$store.appSetViewTitle('About');
  }

  updated() {
    this.updateEnv();
  }

  created() {
    this.updateEnv();
  }
};
